.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}
#fileInput {
  color: transparent;
}
.myInput {
  width: 100%;
  height: auto;
  /* padding: 5px; */
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
}

.input-container {
  display: flex;
  flex-direction: row;
}
.single-container {
  display: flex;
  flex-direction: column;
}
.uploadBtn {
  position: relative;
  border-radius: 10px;
  border-color: transparent;
  background-color: #0b5ed7 !important;
  width: 20%;
  margin-right: 10px;
}

.uploadBtn input[type="file"] {
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 0;
  transform: scale(2);
  overflow: hidden;
  opacity: 0;
  width: 88%;
}
